import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Splide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

AutoPlayCarousel.propTypes = {
  children: arrayOf(PropTypes.element),
};

function AutoPlayCarousel({ children }) {
  const theme = useTheme();
  const { lg, md, sm } = theme.breakpoints.values;

  return (
    <Splide
      options={{
        type: "loop",
        gap: "24px",
        drag: "free",
        arrows: false,
        pagination: false,
        perPage: 4,
        autoScroll: {
          pauseOnHover: true,
          pauseOnFocus: false,
          rewind: false,
          speed: 0.5
        },
        breakpoints: {
          [`${lg}`]: {
            perPage: 3,
            autoScroll: true
          },
          [`${md}`]: {
            perPage: 2,
          },
          [`${sm}`]: {
            perPage: 1,
            autoScroll: false,
            padding: { right: '20%' }
          },
        }
      }}
      extensions={{ AutoScroll }}
    >
      {children}
    </Splide>
  );
};

export default AutoPlayCarousel;

import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import getEventsQuery from '../../../graphql/queries/getEvents.graphql';
import { autoOptimizeImage } from 'utils/functions';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import Event from './Event';

const EventListSkeleton = styled(Skeleton)`
  min-height: 414rem;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: ${prop('$smallSize')}rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-height: ${prop('$mediumSize')}rem;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    min-height: ${prop('$largeSize')}rem;
  }
`;

const EventsListContainer = styled.div`
  min-height: 100vh;
`;

const propTypes = {
  className: PropTypes.string,
};

function EventsList({ className }) {
  const { data, loading, error } = useQuery(getEventsQuery, {
    variables: {
      filters: {
        sortBy: 'event_date',
        sortDirection: 'ascending',
      },
    },
  });

  const events = get(data, 'events.items', []);
  const count = get(data, 'events.count', 0);

  if (!events) {
    return null;
  }

  const datedEvents = [];
  const noDateEvents = [];
  events.forEach((event) => {
    if (event.eventDate) {
      datedEvents.push(event);
    } else {
      noDateEvents.push(event);
    }
  });

  const allEvents = [...datedEvents, ...noDateEvents];

  return (
    <>
    <Helmet>
      {allEvents.length > 0 && allEvents[0]?.eventImage?.[0]?.eventImage?.url && (
        <link
          rel='preload'
          as='image'
          href={autoOptimizeImage(allEvents[0].eventImage[0].eventImage.url, true)}
        />
      )}
    </Helmet>
    <EventsListContainer className={className}>
      {allEvents.map((eventItem, index) => {
        if (index < 2) {
          return <Event eventItem={eventItem} key={`event-${index}`} />;
        }

        return (
          <LazyLoad
            key={`event-${index}`}
            debounce={true}
            height='100%'
            placeholder={
              <EventListSkeleton
                variant='rect'
                width={'100%'}
                $smallSize={(count > 0 ? count : 3) * 63}
                $mediumSize={(count > 0 ? count : 3) * 35}
                $largeSize={(count > 0 ? count : 3) * 38}
              />
            }
          >
            <Event eventItem={eventItem} />
          </LazyLoad>
        );
      })}
    </EventsListContainer>
    </>
  );
}

EventsList.propTypes = propTypes;

export default React.memo(EventsList);

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import NewsBlogFeed from 'components/NewsBlogFeed';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CtaButton from 'components/CtaButton';

const MarketNewsFeedBlockContainer = styled(Box)`
  padding: 5rem;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3rem 1rem;
  }
`
const Header = styled(Typography)`
  text-transform: uppercase;
  border-bottom: 3px solid #e21f26;
  padding-bottom: .33rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 1rem;
  
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 0.5rem;
  }
`;

const ShowMore = styled.div`  
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  bottom: -1.5rem;

  svg {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border: 0.0625rem solid ${prop('theme.colors.lightGray')};
    border-radius: 50%;
    cursor: pointer;
    background-color:  ${prop('theme.colors.white')};
    color: ${prop('theme.colors.black')};

    transition: transform 0.3s; 
    transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'rotate(0deg)')};
    
    &:hover {
      color: ${prop('theme.colors.ttRed')};
    }
  }
`;

const BlogPostFeedWrapper = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-bottom: 1px solid rgba(29, 25, 27, .1);
    padding: 0;
    margin-bottom: 1rem;
    min-height: 129px;
  }

  ${ifProp('$firstItem', css`
    ${({ theme }) => theme.breakpoints.down('xs')} {
       min-height: 300px;
    }
  `)};
`;

const GradientCover = styled.div`
  width: 100%;
  height: 3rem;
  background-image: linear-gradient(rgba(230, 230, 230, 0), #fafafa 97%);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
`;

const BlogPostFeedContainer = styled(Box)`
  height: ${(props) =>
  props.$expanded ||
  props.$isJustInSection ||
  !props.$isMobile ? '100%' : '8rem'};
  overflow: hidden;
`;

const CtaButtonContainer = styled.div`
  text-align: center;
  margin-top: 1.5rem;  
`;

function MarketNewsFeedBlock({ data }) {
  const { marketNewsBlogPostFeed, buttonCta } = data;
  const justInSection = 'just in';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [expandedPosts, setExpandedPosts] = useState([]);

  const handleTogglePostHeight = index => {
    const updatedPosts = [...expandedPosts];
    if (updatedPosts.includes(index)) {
      const indexToRemove = updatedPosts.indexOf(index);
      updatedPosts.splice(indexToRemove, 1);
    } else {
      updatedPosts.push(index);
    }
    setExpandedPosts(updatedPosts);
  };

  return (
    <MarketNewsFeedBlockContainer>
      <Header variant={'h2'} pt={0}>{data.header}</Header>   
      <Grid container spacing={isMobile ? 0 : 3}>
        {
          marketNewsBlogPostFeed.map((post, index) => (
            <BlogPostFeedWrapper item xs={12} sm={6} md={3} lg={3} key={index} $firstItem={index === 0}>
              <Box position={'relative'}>
                <BlogPostFeedContainer               
                  key={index} 
                  $expanded={expandedPosts?.includes(index) ?? false}
                  $isJustInSection={post.header.toLowerCase() === justInSection}
                  $isMobile={isMobile}
                >
                  <NewsBlogFeed key={index} display={'grid'} useSeoData={false} blogPostFeed={post} />
                </BlogPostFeedContainer>
                {post.header.toLowerCase() !== justInSection && isMobile &&
                  <>
                    <ShowMore rotated={expandedPosts.includes(index)} onClick={() => handleTogglePostHeight(index)}>
                      <KeyboardArrowDownIcon />
                    </ShowMore>
                  </>
                }
              </Box>
            </BlogPostFeedWrapper>
          ))
        }
      </Grid> 
      <CtaButtonContainer style={{textAlign:'center'}}>
        <CtaButton data={buttonCta}/>
      </CtaButtonContainer>
    </MarketNewsFeedBlockContainer>
  );
}

export default MarketNewsFeedBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { makeStyles } from '@material-ui/core/styles';

const OpenAccountModalContainer = styled.div`
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ClickableContainer = styled.div`
  width: max-content;
  text-align: center;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const CtaButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 1rem;
  }
`;

const propTypes = {
  contentType: PropTypes.string,
  height: PropTypes.number,
  icon: PropTypes.element,
  padding: PropTypes.oneOf(['none', 'thin', 'narrow', 'medium', 'wide']),
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  bgColor: PropTypes.string,
  hideClose: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  enrichedUrl: PropTypes.string,
}

function LeavingModal({
  open,
  onClose,
  children,
  height = 400,
  padding = 'narrow',
  url,
  page,
  width = 500,
  alignment = 'left',
  bgColor,
  openInNewWindow = true,
  hideClose = false,
  enrichedUrl,
  ...props
 }) {
  let truncatedUrl = url.split('?')[0];
  if (truncatedUrl.includes('open.tastytrade.com')) {
    truncatedUrl = 'open.tastytrade.com';
  }

  const useStyles = makeStyles((theme) => ({
    blackSquareFilled: {
      backgroundColor: theme.colors.dark,
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.darkCharcoal,
        color: theme.colors.white,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Modal
      ariaLabel={`Leaving modal for ${truncatedUrl}`}
      ariaDescribe={'Modal that intercedes before navigating to external link'}
      onClose={onClose}
      open={open}
      padding={padding}
      height={height}
      page={page}
      width={width}
      alignment={alignment}
      bgColor={bgColor}
    >
      <OpenAccountModalContainer>
        <TextContainer>
          You are now leaving tastylive.com and heading to {truncatedUrl}.
          {' '}<strong>Do you want to continue?</strong>
        </TextContainer>
        <CtaButtonContainer>
          <ClickableContainer onClick={() => onClose()}>
            <Button variant={'contained'} className={classes['blackSquareFilled']}>No</Button>
          </ClickableContainer>
          <ClickableContainer onClick={() => onClose()}>
            <Button
              variant={'contained'}
              color={'primary'}
              href={enrichedUrl ?? url}
              target={openInNewWindow ? '_blank' : '_self'}
            >
              Yes
            </Button>
          </ClickableContainer>
        </CtaButtonContainer>
      </OpenAccountModalContainer>
    </Modal>
  );
}

LeavingModal.propTypes = propTypes;

export default LeavingModal;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useClickOutside from 'hooks/useClickOutside';
import episodeFeedShape from 'shapes/episodeFeedShape';

const DropdownMenuContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  font-family: ${prop('theme.typography.fontFamilySecondary')};
`;

const DropdownToggle = styled.div`
  border-radius: .375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${prop('theme.colors.ttRed')};
  padding: .5rem 1rem;
  cursor: pointer;
  
  h3 {
    color: ${prop('theme.colors.white')};
    font-size: 1rem;
    margin: 0;
  }

  svg {
    font-size: 2rem;
    color: ${prop('theme.colors.white')}; 
  }
`;

const DropdownMenuItem = styled.div`
  padding: .625rem;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 700;

  &:hover {
    color: ${prop('theme.colors.ttRed')};
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: .375rem;
  z-index: 1000;
  background-color: ${prop('theme.colors.white')};
  border: 1px solid #ccc;
`;

EpisodeDropdownFeedNav.propTypes = {
  data: arrayOf(episodeFeedShape),
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired 
};

function EpisodeDropdownFeedNav({ data, value, setValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(data?.[0]?.tabTitle ?? '');
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedOption(data[value].tabTitle);
  }, [value]);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(prevState => !prevState);

  const handleSelect = (index) => {
    setValue(index);
    setIsOpen(false);
  };

  return (
    <DropdownMenuContainer ref={dropdownRef}>
      <DropdownToggle onClick={toggleDropdown}>
        <h3>{selectedOption?.toUpperCase()}</h3>
        <ExpandMoreIcon/>
      </DropdownToggle>
      {isOpen && (
        <DropdownMenu>
          {data.map((option, index) => (
            <DropdownMenuItem key={index} onClick={() => handleSelect(index)}>
              {option?.tabTitle}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownMenuContainer>
  );
}

export default EpisodeDropdownFeedNav;

import React from 'react';
import { arrayOf } from 'prop-types';
import modularBlockShape from 'shapes/modularBlockShape';
import {
  Generic,
  GenericCollection,
  JSONLD,
  Question
} from 'react-structured-data';
import { getRawText } from 'utils/rawTextWordCount';

ModularSchema.propTypes = {
  modularBlocks: arrayOf(modularBlockShape),
};

function ModularSchema({ modularBlocks }) {
  const faqs = (modularBlocks && modularBlocks
    .filter(block => block?.basicTextBlock && block?.basicTextBlock?.accordion)
    .map(block => ({ ...block?.basicTextBlock }))) ?? [];

  return (
    <>
      {/* SEO Structured Data - FAQ */}
      {faqs.length > 0 && (
        <JSONLD dangerouslyExposeHtml>
          <Generic
            type='faqPage'
            jsonldtype='FAQPage'
          >
            <GenericCollection type='mainEntity'>
              {faqs.map((faq, index) => (
                <Question
                  name={getRawText(faq.header)}
                  key={index}
                >
                  <Generic
                    jsonldtype='Answer'
                    type='acceptedAnswer'
                    schema={{ text: getRawText(faq.bodyCopy) }}
                  />
                </Question>
              ))}
            </GenericCollection>
          </Generic>
        </JSONLD>
      )}
    </>
  );
}

export default React.memo(ModularSchema);

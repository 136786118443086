import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { prop } from 'styled-tools';
import AutoPlayCarousel from 'components/Carousel/AutoPlayCarousel';
import CtaButton from 'components/CtaButton';
import CarouselCard from 'components/Carousel/CarouselCard';
import KeyConceptsFeedBlockShape from 'shapes/components/keyConceptsFeedBlockShape';

const KeyConceptsFeedContainer = styled.div`
  margin: 3rem 0;
`;

const CtaButtonContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Header = styled(Typography)`
  border-bottom: 3px solid ${prop('theme.colors.ttRed')};
  margin-left: 5rem;
  margin-bottom: 2rem;
  padding-bottom: .33rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: inline-block;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 1rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 1.5rem;
  }
`;

KeyConceptsFeedBlock.propTypes = {
  data: KeyConceptsFeedBlockShape
};

function KeyConceptsFeedBlock({ data }) {
  const { reference: carouselCardData, buttonCta, header } = data;

  return (
    <KeyConceptsFeedContainer>
      { header && (
        <Header variant={'h2'}>{header}</Header>
      )}
      <AutoPlayCarousel>
        {carouselCardData.map((cardData) => (
          <CarouselCard
            key={cardData.title}
            image={cardData.thumbnailImage}
            title={cardData.title}
            body={cardData.definition}
            url={cardData.url}
            buttonText={'READ MORE'}
            gtmEvent='Trading concepts feed card clicked'
          />
        ))}
      </AutoPlayCarousel>
      <CtaButtonContainer>
        <CtaButton data={buttonCta}/>
      </CtaButtonContainer>
    </KeyConceptsFeedContainer>
  );
};

export default KeyConceptsFeedBlock;

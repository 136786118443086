import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ifProp, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useHistory } from 'react-router-dom';
import SuperNavLink from 'components/Header/components/SuperNavLink';
import { useDashboardContext } from 'context/DashboardContext';
import { useScrollPosition } from 'hooks/useScrollPosition';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MainNavMobile from 'components/Header/components/MainNavMobile';
import SuperNavMobile from 'components/Header/components/SuperNavMobile';
import { useAuth } from 'context/AuthContext';
import BaseAvatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FullWidthSearchBar from './components/FullWidthSearchBar';
import CloseIcon from '@material-ui/icons/Close';
import FirstTimeDashboardHelper from './components/FirstTimeDashboardHelper';
import SubsequentDashboardHelper from './components/SubsequentDashboardHelper';
import MobileHeaderNavBar from './components/MobileHeaderNavBar';
import MainHeaderNavBar from './components/MainHeaderNavBar';
import Dashboard from '../../routes/Dashboard';
import { dataLayerPush } from 'utils/dataLayer';
import LeavingModal from '../LeavingModal';

const NavContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;

  .MuiToolbar-gutters {
    padding-left: 0;
    padding-right: 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }

  @media (min-width: 1440px) {
    max-width: 1380px;
    width: 88%;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    max-width: 1580px;
  }
`;

const AppBar = styled(BaseAppBar)`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  top: 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    z-index: 1106;
  }

  ${ifProp('$displayAlert', css`
    top: 56px;
  `)}

  ${ifProp('$displayAlert', css`
    ${({ theme }) => theme.breakpoints.down('xs')} {
      top: 135px;
    }
  `)}
`;

const SuperNavContainer = styled(NavContainer)`
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: flex-end;
`;

const SuperNavToolbar = styled(Toolbar)`
  background-color: ${theme('colors.black')};
  height: 2.5rem;
  min-height: auto;
  z-index: 2000;
`;

const DashboardWrapper = styled.div`
  height: 100%;
  outline: none; //nested element, solves chrome unwanted border issue but will not affect keyboard only use of the app
`;

const MainNavToolbar = styled(Toolbar)`
  min-height: 3.75rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 1119px) {
    padding-right: 0;
  }
`;

const TWBrokerageButton = styled(Button)`
  opacity: 0;
  transition: opacity 500ms ease-in;
  background-color: ${theme('colors.ttRed')};

  ${ifProp('$display', css`
    opacity: 1;
    width: 12.8125rem;
    height: 1.3125rem;
    font-size: 0.8125rem;
    border-radius: 3px;
    border: solid 1px rbga(226,32,40);
    font-stretch: condensed;
    padding: 0;
    letter-spacing: 0.26px;
    margin-right: 3.56rem;
  `, css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px; //not ideal but leaving it like this for now
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  `)}
`;

const FullWidthSearchNavWrapper = styled.div`
  height: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1500;
  background-color: white;
  transition: height 400ms ease-in;

  ${ifProp(
    'show',
    css`
      height: 100%;
    `,
    css`
      height: 0;
    `
  )}
`;

const StyledAvatar = styled(BaseAvatar)`
  width: 1.6875rem;
  height: 1.6875rem;
`;

const MobileBottomBar = styled(BaseAppBar)`
  height: 3.125rem;
  bottom: 0;
  top: auto;
  color: ${theme('palette.common.white')};
  background-color: ${theme('palette.common.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1rem;
  cursor: pointer;

  .MuiContainer-root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${ifProp({'$showFirstTimeHelper': true}, css`
    z-index: 1302;
  `)}
`;

const DashboardLink = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  .MuiSvgIcon-root {
    margin-right: .8125rem;
  }
`;

const CloseIconButton = styled(CloseIcon)`
  cursor: pointer;
  padding-right: 0;
`;

const ContainerWrapper = styled(Container)`
  position: relative;
`;

Header.propTypes = {
  data: PropTypes.object,
  displayAlert: PropTypes.bool
};

function Header({ data, displayAlert }) {
  const { user } = useAuth();
  const { state: dashboardState, toggleDashboard, setFirstTimeHelper, setSubsequentHelper } = useDashboardContext();
  const [showTWBrokerageButton, setShowTWBrokerageButton] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileSuperNavOpen, setMobileSuperNavOpen] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [openNavModal, setOpenNavModal] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const { logo, primaryNavNew, superNav } = data || {};
  const menuItems = primaryNavNew && primaryNavNew.menuItems;
  const superNavItems = superNav?.menuItems;
  const trigger = useScrollTrigger({ disableHysteresis: true });

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Open a tastytrade account'`);
    setOpenNavModal(true);
  };

  const handleClose = () => {
    setOpenNavModal(false);
  };

  useEffect(() => {
    if (window && window.innerWidth > theme.breakpoints.values['sm']) {
      function handleResize() {
        setSubsequentHelper(false);
      }

      window.addEventListener('resize', handleResize);
      handleResize();
    }
  },[]);

  useScrollPosition(({ currPos }) => {
    const isShow = currPos.y < 0;
    if (isShow !== showTWBrokerageButton) {
      setShowTWBrokerageButton(isShow)
      }
  }, [showTWBrokerageButton]);

  const onRequestSearch = (value) => {
    // Workaround issue with search-ui context not updated if we're already on the search page.
    // We push to the wrong page and then replace it in the history immediately so the page refreshes.
    history.push('/temp?q=${value}');
    setTimeout(() => {
      history.replace(`/search?q=${value}`);
    });
  };

  const handleMobileDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
    setMobileSuperNavOpen(false);
    dataLayerPush('Mobile nav toggled');
  }

  const handleMobileSuperNavToggle = () => {
    setMobileSuperNavOpen(!mobileSuperNavOpen);
    setMobileNavOpen(false);
    dataLayerPush('Mobile supernav toggled');
  }

  const handleClickDashboard = () => {
    toggleDashboard();
    setFirstTimeHelper(false);
  }

  const handleOpenSearch = () => {
    setShowSearchBar(true)
  }

  const FullWidthSearchNav = () => (
    <>
      <FullWidthSearchBar onRequestSearch={onRequestSearch} setShowSearchBar={setShowSearchBar} showSearchBar={showSearchBar} />
      <CloseIconButton
        aria-label='Search'
        color="inherit"
        edge="start"
        onClick={() => setShowSearchBar(false)}
      >
        <CloseIcon />
      </CloseIconButton>
    </>
  );

  return (
    <>
      <AppBar
        position='fixed'
        $displayAlert={displayAlert}
        $trigger={trigger}
      >
        <Hidden smDown implementation='css'>
          <SuperNavToolbar>
            <SuperNavContainer>
              <TWBrokerageButton
                color='secondary'
                variant='contained'
                $display={showTWBrokerageButton}
                onClick={() => {
                  handleOpen();
                }}
              >
                OPEN A tastytrade ACCOUNT
              </TWBrokerageButton>
              {superNavItems && superNavItems.map((superNavItem, index) => (
                <SuperNavLink menuItem={superNavItem} key={index} />
              ))}
            </SuperNavContainer>
          </SuperNavToolbar>
          <LeavingModal
            onClose={handleClose}
            open={openNavModal}
            padding='narrow'
            url='https://open.tastytrade.com/signup?utm_medium=internal_link&utm_source=100'
            testId='openTastyTradeAccountModal'
          />
        </Hidden>
        <NavContainer>
          <MainNavToolbar>
            <Hidden mdUp implementation='js'>
              <MobileHeaderNavBar
                logo={logo}
                mobileNavOpen={mobileNavOpen}
                handleMobileSuperNavToggle={handleMobileSuperNavToggle}
                handleOpenSearch={handleOpenSearch}
                StyledAvatar={StyledAvatar}
                handleMobileDrawerToggle={handleMobileDrawerToggle}
                user={user}
                menuItems={menuItems}
              />
            </Hidden>

            <Hidden smDown implementation='js'>
              <MainHeaderNavBar
                logo={logo}
                handleOpenSearch={handleOpenSearch}
                showTWBrokerageButton={showTWBrokerageButton}
                menuItems={menuItems}
                displayAlert={displayAlert}
              />
            </Hidden>
            <FullWidthSearchNavWrapper show={showSearchBar}>
              <FullWidthSearchNav />
            </FullWidthSearchNavWrapper>
          </MainNavToolbar>
        </NavContainer>
      </AppBar>

      {/* Main Nav in Mobile */}
      <Hidden mdUp implementation='css'>
        <MainNavMobile
          displayAlert={displayAlert}
          menuItems={menuItems}
          open={mobileNavOpen}
          onClose={handleMobileDrawerToggle}
        />
      </Hidden>

      {/* Super Nav in Mobile */}
      <Hidden mdUp implementation='css'>
        <SuperNavMobile
          displayAlert={displayAlert}
          menuItems={superNavItems}
          open={mobileSuperNavOpen}
          onClose={handleMobileSuperNavToggle}
        />
      </Hidden>

      {/* Dashboard */}
      {!!user && (
        <Modal open={dashboardState.isOpen}>
          <Slide direction={isMobileOrTablet ? 'up' : 'left'} in={dashboardState.isOpen}>
            <DashboardWrapper>
              <Dashboard />
            </DashboardWrapper>
          </Slide>
        </Modal>
      )}

      {/* Mobile bottom navbar for dashboard toggle */}
      {!!user && (
        <Hidden mdUp implementation='css'>
          <MobileBottomBar
            position='fixed'
            role='button'
            tabIndex='0'
            aria-expanded={dashboardState.isOpen}
            aria-label='Open dashboard'
            onClick={handleClickDashboard}
            $showFirstTimeHelper={dashboardState.showFirstTimeHelper}
          >
            <SubsequentDashboardHelper>
              <ContainerWrapper>
                <FirstTimeDashboardHelper arrowDirection='down' />
                <DashboardLink>
                  <DashboardIcon />
                  Dashboard
                </DashboardLink>
                <KeyboardArrowUpIcon fontSize='large' />
              </ContainerWrapper>
            </SubsequentDashboardHelper>
          </MobileBottomBar>
        </Hidden>
      )}
    </>
  );
}
export default React.memo(Header);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListCard from './ListCard';
import GridCard from './GridCard';
import blogFeedPosterShape from 'shapes/blogFeedPosterShape';
import blogPostShape from 'shapes/blogPostShape';
import { autoOptimizeImage } from 'utils/functions';

const displayComponents = {
  'list': ListCard,
  'grid': GridCard
};

const StyledPosterDescription = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.85rem;
  font-weight: 700;
  margin-right: 0.5rem;
  color: ${theme('colors.white')}
`;

BlogContent.propTypes = {
  blogFeedPoster: blogFeedPosterShape,
  display: PropTypes.string,
  header: PropTypes.string,
  useSeoData: PropTypes.bool,
  blogData: PropTypes.arrayOf(blogPostShape),
};

function BlogContent({ blogFeedPoster, blogData, display, useSeoData, header }) {
  const { blogFeedImage, posterLink, posterDescription } = blogFeedPoster;
  const CardComponent = displayComponents[display] || ListCard;
  const justInSection = 'just in';

  return (
    <>
      {/* Blog poster */}
      {blogFeedImage && (
        <Box padding='1rem 1rem 1rem 0.5rem'>
          <a href={posterLink?.href ?? '#'} target='_blank' rel='noopener noreferrer'>
            <img
              loading='lazy'
              width='100%'
              src={autoOptimizeImage(blogFeedImage.url, true, 650)}
              alt={posterLink.title || 'Poster'}
            />
          </a>
          <StyledPosterDescription>{posterDescription}</StyledPosterDescription>
        </Box>
      )}

      {/* Blog posts */}
      {blogData?.map((blogPost) => (
        <CardComponent
          blogPost={blogPost}
          isJustInSection={header.toLowerCase() === justInSection}
          useSeoData={useSeoData}
          key={blogPost.uid}
        />
      ))}
    </>
  );
}

export default BlogContent;
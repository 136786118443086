import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client'; 
import getBlogPostsQuery from 'graphql/queries/getBlogPosts.graphql';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import blogPostFeedBlockShape from 'shapes/blogPostFeedBlockShape';
import BlogAccordion from './components/BlogAccordion';
import BlogContent from './components/BlogContent';

const BlogHeader = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;

  ${props => props.theme.breakpoints.down('md')} {
    font-size: 0.875rem;
  }

  ${ifProp('$underlineText', css`
      display: block;
      margin-bottom: .25rem;
      border-bottom: 1px solid rgba(29, 25, 27, .1);
  `)}

  ${ifProp('$padding', css`
      padding-left: 1rem;
  `)}
`;

NewsBlogFeed.propTypes = {
  blogPostFeed: blogPostFeedBlockShape,
  display: PropTypes.string,
  useSeoData: PropTypes.bool,
};

function NewsBlogFeed({ blogPostFeed, display, useSeoData }) {
  const [blogData, setBlogData] = useState(null);
  const { headerType, header, blogTag, blogFeedPoster, blogPostCount, pinnedPosts } = blogPostFeed;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const client = useApolloClient();
  const justInSection = 'just in';

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const blogPostSortMap = {
        mostRecent: {
          sortBy: 'post_date',
          sortDirection: 'descending',
        }
      };

      const sort = blogPostSortMap['mostRecent'];
      const pagination = { limit: blogPostCount || 4 };

      const queryVariables = {
        skip: typeof window === 'undefined',
        pagination,
        sort,
        filters: {
          blogTagUids: blogTag && blogTag.length > 0 && blogTag[0].uid ? blogTag[0].uid : []
        }
      };

      try {
        const result = await client.query({
          query: getBlogPostsQuery,
          variables: queryVariables,
          fetchPolicy: 'network-only',
        });

        setBlogData(result);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, [client, blogPostCount, blogTag]);

  const originalPosts = pinnedPosts
    ? pinnedPosts.map((pin) => pin.blogPost).concat(blogData?.data?.blogPosts?.items).filter((val) => val)
    : blogData?.data?.blogPosts?.items;

  const limitedPosts = (blogTag.length <= 0 && header.toLowerCase() !== justInSection) ?
    originalPosts?.slice(1, blogPostCount+1 || 5) ?? []
    : originalPosts?.slice(0, blogPostCount || 4) ?? [];
  return (
    <Box>
      {blogData && (
        <>
          {blogData.loading && <Box pt={2} pl={2}>Loading...</Box>}
          {blogData.error && (
            <Box mt={3}>
              <Alert severity='error'>
                Failed to load market news, please reload to try again.
              </Alert>
            </Box>
          )}
        </>
      )}

      {isMobile && display === 'list' ? (
        <BlogAccordion title={header}>
          <BlogContent blogFeedPoster={blogFeedPoster} blogData={limitedPosts} display={display} useSeoData={useSeoData} header={header}/>
        </BlogAccordion>
      ) : (
        <Box sx={{ padding: { xs: 0, md: 2, }}} pt={2}>
          <BlogHeader $underlineText={display === 'grid'} $padding={display=== 'list'} variant={(headerType && headerType.toLowerCase()) ?? 'h3'}>
            {header}
          </BlogHeader>
          <BlogContent blogFeedPoster={blogFeedPoster} blogData={limitedPosts} display={display} useSeoData={useSeoData} header={header}/>
        </Box>
      )}
    </Box>
  );
}

export default NewsBlogFeed;